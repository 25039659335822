<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      비밀번호 찾기<router-link to="/member/login"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="search_idpw">
      <h3>아래 절차를 따라 임시 비밀번호를 받으세요.</h3>

      <div class="result">
        <ul>
          <li>
            <h4>◎ 해당 아이디 : {{ userid }}</h4>
            <span>(가입 : {{ registDate }})</span>
          </li>
          <li>
            <h4>◎ 회원정보에 등록된 이메일로 임시 비번 받기</h4>
            <p>
              해당 아이디의 계정에 등록된 아래 이메일 주소로 임시 비밀번호가
              발송됩니다. 임시 비밀번호를 받으려면
              <b>[임시 비밀번호 받기]</b> 버튼을 눌러주세요.
            </p>
          </li>
        </ul>
        <div class="mail">{{ email }}</div>
      </div>

      <div class="info">
        <div class="box">
          <div class="top">
            ※ 임시 비밀번호가 오지 않나요?<router-link
              to=""
              @click.native="open($event)"
              >∨</router-link
            >
          </div>
          <ul style="display: none">
            <!-- <li>
              램이 발송한 메일이 스팸 메일함에 있는지 확인해주세요. 램이 발송한
              메일이 스팸 메일함에 없을 경우 <b>[임시 비밀번호 받기]</b>를 다시
              한번 눌러주세요.
            </li> -->
            <li>
              램이 발송한 메일이 스팸 메일함에 있는지 확인해주세요. 램이 발송한
              메일이 스팸 메일함에 없을 경우 <b>[비밀번호 찾기]</b>를 다시 한번
              실행해주세요.
            </li>
          </ul>
        </div>
      </div>

      <div class="btn">
        <!-- <a href="#" class="yellow">임시 비밀번호 받기</a> -->
        <router-link to="/member/login" class="white">로그인</router-link>
      </div>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    if (!this.isCheckPhone) {
      this.$toast.default("휴대폰 인증이 필요합니다.");
      this.$router.push("/member/find_id");
    }
  },
  methods: {
    open(e) {
      const ulEl = e.target.closest(".box").querySelector("ul");
      if (ulEl.style.display == "none") {
        e.target.textContent = "∧";
        ulEl.style.display = "block";
      } else {
        e.target.textContent = "∨";
        ulEl.style.display = "none";
      }
    },
  },
  computed: {
    ...mapState("member", [
      "msg",
      "result",
      "userid",
      "email",
      "registDate",
      "isCheckPhone",
    ]),
  },
};
</script>

<style></style>
